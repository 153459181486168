import React, {Component} from "react";
import Iranyitopult from "./components/Iranyitopult";
import {
    BrowserRouter as Router,
    Route,
    Routes
}
    from "react-router-dom"
import KezdolapPage from "./pages/kezdolap/KezdolapPage";
import {BolygokPage, JegyekPage, HazakPage, HUHkPage, HJkPage, BJkPage, BHkPage} from "./pages/analogiadb/AlapAnalogiaTarolok";
import {BolygoPage, JegyPage, HazPage, HUHPage, HJPage, BJPage, BHPage} from "./pages/analogiadb/AnalogiaPage";
import BesorolatlanAnalogiak from "./pages/analogiadb/besorolatlananalogiak/Besorolatlananalogiak"

import PrivateRoutes from "./components/PrivateRoutes"
import LoginPage from "./pages/userkezeles/LoginPage"
import ForgotPassword from "./pages/userkezeles/ForfotPassword"
import RegistrationPage from "./pages/userkezeles/RegistrationPage"
import PrivacyPolicy from "./pages/userkezeles/PrivacyPolicy"
import {AuthProvider} from "./pages/userkezeles/AuthContext";
import {PermissionsProvider} from "./pages/userkezeles/PermissionContext";
import Profil from "./pages/userkezeles/profil/Profil";
import Helyezetek from "./pages/userkezeles/Helyezetek";
import Iranyzat from "./pages/iranyzatok/Iranyzat";
import IranyzatDetail from "./pages/iranyzatok/IranyzatDetail";
import GyakorloOpciok from "./pages/gyakorlo/GyakorloOpciok";
import Gyakorlo from "./pages/gyakorlo/Gyakorlo";
import HibajelentesTargyalo from "./pages/kozosseg/HibajelentesTargyalo"
import UjHibajelentes from "./pages/analogiadb/hibajelentes/UjHibajelentes"
import Footer from "./components/Footer";
import Rolam from "./components/Rolam";
import CookieNotification from "./components/CookieNotification";
import Szemelyek from "./pages/kozosseg/szemely/Szemelyek";
import FejezetList from "./pages/leckek/FejezetList";
import Lecke from "./pages/leckek/Lecke";
import Test from "./pages/leckek/Test";

import CourseForm from "./pages/leckek/course/CourseForm";
import CourseList from "./pages/leckek/course/CourseList";
import LessonList from './pages/leckek/lessons/LessonList';
import LessonForm from './pages/leckek/lessons/LessonForm';
import LevelForm from './pages/leckek/lessons/LevelForm';
import LevelExercise from './pages/leckek/levelexercise/LevelExercise';

import NewsFeed from "./pages/kozosseg/hir/NewsFeed";
import JegyJatek from "./pages/gyakorlo/jatekok/JegyJatek";
import AstrologyGame from "./pages/jatekok/AstrologyGame";
import AnalogiaSearch from './pages/analogiadb/kereso/AnalogiaKereso';
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

// import DjangoAdminComponent from './pages/userkezeles/DjangoAdminComponent';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewCompleted: false,
            todoList: [],
            modal: false,
            activeItem: {
                title: "",
                description: "",
                completed: false,
            },
        };
    }

    render() {
        const { pathname } = window.location;
        const isHome = pathname === "/";
        return (
            <QueryClientProvider client={queryClient} contextSharing={true}>
                <Router>
                    <div className={isHome ? "" : "container"}>
                        <AuthProvider>
                        <PermissionsProvider>
                            <Iranyitopult/>
                            <Routes>
                                <Route element={<PrivateRoutes/>}>
                                    <Route path="/profil" element={<Profil/>}/>
                                    <Route path="/kozosseg" element={<NewsFeed/>}/>
                                    <Route path="/gyakorloopciok" element={<GyakorloOpciok/>}/>
                                    <Route path="/gyakorlo/:id" element={<Gyakorlo/>}/>
                                    <Route path="/helyezettek" element={<Helyezetek/>}/>
                                    <Route path="/uj-hibajelentes/:analogiaid" element={<UjHibajelentes/>}/>
                                    <Route path='/hibajelentestargyalo' element={<HibajelentesTargyalo/>}/>
                                    <Route path='/szemelyek' element={<Szemelyek/>}/>
                                    <Route path='/leckefejezetek' element={<FejezetList/>}/>
                                    <Route path="/lecke/:id" element={<Lecke/>} />
                                    <Route path="/lecke/:id/test" element={<Test />} />
                                    <Route path="/analogiakereso" element={<AnalogiaSearch />} />
                                    <Route path="/iranyzatok" element={<Iranyzat />} />
                                    <Route path="/iranyzat/:id" element={<IranyzatDetail />} />
                                    <Route path="/szemelyek" element={<Szemelyek />} />
                                    <Route path="/besorolatlananalogiak" element={<BesorolatlanAnalogiak />} />
                                    {/* <Route path="/szemely/:id" element={<SzemelyDetail />} /> */}
                                    {/* <Route path="/admin" element={<DjangoAdminComponent/>} /> */}

                                </Route>

                                <Route path="/tanulokozpont" element={<CourseList />} />
                                <Route path="/tanulokozpont/new" element={<CourseForm />} />
                                <Route path="/tanulokozpont/:id" element={<CourseForm />} />
                                <Route path="/tanulokozpont/:courseId/lecke" element={<LessonList />} />
                                <Route path="/tanulokozpont/:courseId/lecke/new" element={<LessonForm />} />
                                <Route path="/tanulokozpont/:courseId/lecke/:lessonId" element={<LessonForm />} />
                                <Route path="/tanulokozpont/:courseId/lecke/:lessonId/melyseg/new" element={<LevelForm />} />
                                <Route path="/tanulokozpont/:courseId/lecke/:lessonId/melyseg/:levelId" element={<LevelForm />} />
                                <Route path="/tanulokozpont/:courseId/lecke/:lessonId/melyseg/:levelId/start" element={<LevelExercise />} />


                                <Route path="/jegyjatek" element={<JegyJatek />} />
                                <Route path="/zoodiakusjatek" element={<AstrologyGame />} />

                                <Route path="/bolygok" element={<BolygokPage/>}/>
                                <Route path="/bolygok/:id" element={<BolygoPage/>}/>
                                <Route path="/jegyek" element={<JegyekPage/>}/>
                                <Route path="/jegyek/:id" element={<JegyPage/>}/>
                                <Route path="/hazak" element={<HazakPage/>}/>
                                <Route path="/bolygokJegyekben" element={<BJkPage/>}/>
                                <Route path="/bolygokHazakban" element={<BHkPage/>}/>
                                <Route path="/hazakJegyekben" element={<HJkPage/>}/>
                                <Route path="/hazakUraHazakban" element={<HUHkPage/>}/>
                                <Route path="/bolygokJegyekben/:id" element={<BJPage/>}/>
                                <Route path="/bolygokHazakban/:id" element={<BHPage/>}/>
                                <Route path="/hazakJegyekben/:id" element={<HJPage/>}/>
                                <Route path="/hazakUraHazakban/:id" element={<HUHPage/>}/>
                                <Route path="/hazak/:id" element={<HazPage/>}/>
                                <Route path="/register" element={<RegistrationPage/>}/>
                                <Route path="/" element={<KezdolapPage/>}/>
                                <Route path='/login' element={<LoginPage/>}/>
                                <Route path='/elfelejtett-jelszo' element={<ForgotPassword/>}/>
                                <Route path='/rolam' element={<Rolam/>}/>
                                <Route path='/adatkezelesi-nyilatkozat' element={<PrivacyPolicy/>}/>

                            </Routes>
                        </PermissionsProvider>
                        </AuthProvider>
                        <Footer/>
                        <CookieNotification />
                    </div>
                </Router>
            </QueryClientProvider>
        );
    }
}

export default App;
