import React, { useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function IranyzatList({ iranyzatok }) {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const navigate = useNavigate();

  const toggleDescription = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const navigateToDetails = (iranyzatId) => {
    navigate(`/iranyzat/${iranyzatId}`);
  };

  return (
    <>
      {iranyzatok && (
        <div className="iranyzat-list mt-4">
          {iranyzatok.map((iranyzat, index) => (
            <div
              key={index}
              className="iranyzat-item mb-3 p-3 border rounded"
              onClick={() => navigateToDetails(iranyzat.id)} // Navigate to the detail page on click
              style={{ cursor: 'pointer' }}
            >
              <h4>{iranyzat.iranyzat_tipus}</h4>
              <p><strong>Ágazat:</strong> {iranyzat.agazat}</p>
              <div className="d-flex justify-content-between align-items-center">
                <Badge
                  pill
                  variant={iranyzat.public_lathatosag ? 'success' : 'warning'}
                  className="mr-3"
                >
                  {iranyzat.public_lathatosag ? 'Publikus' : 'Privát'}
                </Badge>
                <Button
                  variant="link"
                  className="text-decoration-none"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents the parent div click event from triggering
                    toggleDescription(index);
                  }}
                >
                  {expandedIndex === index ? 'Leírás elrejtése' : 'Leírás megtekintése'}
                </Button>
              </div>
              {expandedIndex === index && (
                <div className="iranyzat-description mt-2">
                  <p><strong>Leírás:</strong> {iranyzat.leiras}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default IranyzatList;
