import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { usePost } from "../../auth/apicalls";
import { showErrorAlert, showSuccessAlert } from "../../alert/ErrorAlert";
import { useQueryClient } from "react-query";

function CreateBesorolatlanAnalogia({showModal, setShowModal}) {
  const queryClient = useQueryClient();

  const [formData, setFormData] = useState({
    aanalogia_tipus: '',
    tartalom: '',
  });

  const { mutate: postBesorolatlanAnalogia } = usePost(`/api/besorolatlan_analogia/`, {
    onSuccess: () => {
      queryClient.invalidateQueries('/api/besorolatlan_analogia/');
      setShowModal(false);
      showSuccessAlert({title: "Sikerült létrehozni az analógia javaslatot"})
    },
    onError: (error) => {
      setShowModal(false);
      showErrorAlert({
        title: "Nem sikerült létrehozni az analógia javaslatot!",
        text: error.response.data.user_error
      });
    },
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = () => {
    postBesorolatlanAnalogia(formData);
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
    <Modal.Header closeButton>
        <Modal.Title>Új analógia javaslat létrehozása</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <Form>
        <Form.Group controlId="aanalogia_tipus">
            <Form.Label>(Absztrakt) analógia típus</Form.Label>
            <Form.Control as="select" name="aanalogia_tipus" value={formData.aanalogia_tipus} onChange={handleInputChange}>
            <option value="">Válassz típust</option>
            <option key="bolygó" value="bolygo">Bolygó</option>
            <option key="ház" value="haz">Ház</option>
            <option key="jegy" value="jegy">Jegy</option>
            {/* <option key="Bolygó a házban" value="bh">Bolygó a házban</option>
            <option key="Bolygó a jegyben" value="bj">Bolygó a jegyben</option>
            <option key="Ház a jegyben" value="hj">Ház a jegyben</option>
            <option key="Ház ura házban" value="huh">Ház ura házban</option> */}
            </Form.Control>
        </Form.Group>

        <Form.Group controlId="tartalom" className='mt-3'>
            <Form.Label>Tartalom</Form.Label>
            <Form.Control
            type="text"
            name="tartalom"
            value={formData.tartalom}
            onChange={handleInputChange}
            />
        </Form.Group>
        </Form>
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
        Mégse
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
        Mentés
        </Button>
    </Modal.Footer>
    </Modal>
  );
}

export default CreateBesorolatlanAnalogia;
