import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Row, Col, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePermissions } from '../../userkezeles/PermissionContext';

import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

const AnalogiaSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [csoportNev, setCsoportNev] = useState('');
  const [analogiaTipus, setAnalogiaTipus] = useState('');
  const [analogiaList, setAnalogiaList] = useState([]);
  const [orderBy, setOrderBy] = useState({ column: '', direction: 'asc' });
  const { analogiaKeresoJog } = usePermissions();

  const searchDelay = 1000;

  const fetchAnalogia = async () => {
    try {
      const response = await axios.get('/api/analogiakereso/', {
        params: {
          tartalom: searchTerm,
          csoport_nev: csoportNev,
          analogia_tipus: analogiaTipus,
        },
      });
      setAnalogiaList(response.data);
    } catch (error) {
      console.error('Error fetching analogia:', error);
    }
  };

  useEffect(() => {
    // Az időzítő
    const timer = setTimeout(() => {
      fetchAnalogia();
    }, searchDelay);

    // Előző időzítő törlése
    return () => clearTimeout(timer);
  }, [searchTerm, csoportNev, analogiaTipus]);

  const handleSearch = (e) => {
    e.preventDefault();
    fetchAnalogia();
  };

  const orderByColumn = (columnName) => {
    const isAsc = orderBy.column === columnName && orderBy.direction === 'asc';
    const direction = isAsc ? 'desc' : 'asc';
    setOrderBy({ column: columnName, direction: direction });
    const sortedList = [...analogiaList].sort((a, b) => {
      if (a[columnName] < b[columnName]) {
        return isAsc ? -1 : 1;
      }
      if (a[columnName] > b[columnName]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
    setAnalogiaList(sortedList);
  };

  const renderSortIcon = (columnName) => {
    if (orderBy.column !== columnName) {
      return <FontAwesomeIcon icon={faSort} />;
    }
    if (orderBy.direction === 'asc') {
      return <FontAwesomeIcon icon={faSortUp} />;
    } else {
      return <FontAwesomeIcon icon={faSortDown} />;
    }
  };

  if (!analogiaKeresoJog) return <div className='m-5 text-center'>
    Az analógiakereső eléréséhez jelenleg nincs jogosultságod!
  </div>

  return (
    <div className='mb-5'>
      <h1 className='text-center mt-5'>Analógia Kereső</h1>
      <div className='text-muted text-center mt-2 mb-5'>
        Kezdj el gépelni bármit, akár egyszerre több szűrési feltétellel!
      </div>
      <Form onSubmit={handleSearch}>
        <Row className="mb-3">
          <Col>
            <Form.Control
              type="text"
              placeholder="Tartalom"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Col>
          <Col>
            <Form.Control
              type="text"
              placeholder="Csoport"
              value={csoportNev}
              onChange={(e) => setCsoportNev(e.target.value)}
            />
          </Col>
          <Col>
            <Form.Select
              value={analogiaTipus}
              onChange={(e) => setAnalogiaTipus(e.target.value)}
            >
              <option value="">Absztrakt analógia</option>
              <option value="bolygo">Bolygó</option>
              <option value="jegy">Jegy</option>
              <option value="haz">Ház</option>
              <option value="bolygo_jegyben">Bolygó Jegyben</option>
              <option value="bolygo_hazban">Bolygó Hazban</option>
              <option value="haz_jegyben">Ház Jegyben</option>
              <option value="haz_ura_hazban">Ház Ura Hazban</option>
            </Form.Select>
          </Col>
        </Row>
      </Form>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th onClick={() => orderByColumn('tartalom')}>
              Tartalom {renderSortIcon('tartalom')}
            </th>
            <th onClick={() => orderByColumn('csoport')}>
              Csoport {renderSortIcon('csoport')}
            </th>
            <th onClick={() => orderByColumn('aa')}>
              Absztrakt analógia {renderSortIcon('aa')}
            </th>
          </tr>
        </thead>
        <tbody>
          {analogiaList.map((analogia) => (
            <tr key={analogia.id}>
              <td>{analogia.tartalom}</td>
              <td>{analogia.csoport}</td>
              <td>{analogia.aa}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AnalogiaSearch;
