import React, { useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import AuthContext from "../../userkezeles/AuthContext";
import { KommentekIcon, KukaIcon } from '../../../utils/Ikonok';
import {useDelete} from "../../auth/apicalls"
import {showErrorAlert} from "../../alert/ErrorAlert"
import { useQueryClient } from "react-query";
import {Plusz} from "../../../utils/Ikonok";
import { usePermissions } from '../../userkezeles/PermissionContext';


export const ActionButtonGroup = ({post, setActivePostId, expandedComments, setExpandedComments}) => {
  const {user} = useContext(AuthContext);
  const queryClient = useQueryClient();
  const { posztLetrehozasJog, kommentelesJog } = usePermissions();


  const { mutate: deleteHir} = useDelete(`/api/hir/${post.id}/`, {
      onSuccess: () => {
          queryClient.invalidateQueries("/api/hir/");
      },
      onError: (error) => {
          showErrorAlert({
              title: "Nem sikerült törölni a posztot!",
              text: error.response.data.user_error
            });
      },
  });

  const handleCommentButtonClick = (postId) => {
    setActivePostId(postId);
  };

  const handleDelete = async (postId) => {
    deleteHir()
  };

  const toggleComments = (postId) => {
    setExpandedComments((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));
  };

  const closeComments = (postId) => {
    setExpandedComments((prev) => ({
      ...prev,
      [postId]: false,
    }));
  };

  return (

    <div className='row'>
        <div className='col-4'>
        {post.hozzaszolasok && post.hozzaszolasok.length >= 1 && !expandedComments[post.id] && (
        <button
        className="btn badge"
        style={{ width: '100%', whiteSpace: 'normal', display: 'inline-block' }}
        onClick={() => toggleComments(post.id)}
        >
        <FaAngleDown /> <KommentekIcon/>
        </button>
    )}

    {expandedComments[post.id] && (
        <button 
        className="btn badge"
        style={{ width: '100%', whiteSpace: 'normal', display: 'inline-block' }}
        onClick={() => closeComments(post.id)}
        >
        <FaAngleUp /> <KommentekIcon/>
        </button>
    )}
        </div>
        <div className='col-4'>
        {posztLetrehozasJog && user.userid === post.user_id && (
        <button
            className="btn badge"
            onClick={() => handleDelete(post.id)}
            style={{ width: '100%', whiteSpace: 'normal', display: 'inline-block' }}
        >
            <KukaIcon/>
        </button>
        )}
        </div>
        {kommentelesJog &&
        <div className='col-4'>
        <button
            className="btn badge"
            onClick={() => handleCommentButtonClick(post.id)}
            style={{ width: '100%', whiteSpace: 'normal', display: 'inline-block' }}
        >
            <Plusz/> <KommentekIcon/>
            </button>
        </div>
        }
    </div>

  );
};

