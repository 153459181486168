// src/context/PermissionsContext.js
import React, { createContext, useContext } from 'react';
import AuthContext from "./AuthContext";

const PermissionContext = createContext();

const determinePermissions = (user) => {

  if (!user) {
    return {
      hibajelentesJog: false,
      posztLetrehozasJog: false,
      analogiaLetrehozasJog: false,
      analogiaErtekelesJog: false,
      kommentelesJog: false,
      hibajelentesSzavazatJog: false,
      analogiaKeresoJog: false,
      besorolatlanAnalogiaJog: false,
    };
  }
  const hibajelentesSzSzint = 6
  const posztLetrehozasSzSzint = 4
  const analogiaLetrehozasSzSzint = 10
  const analogiaErtekelesSzSzint = 8
  const kommentelesSzSzint = 2
  const besorolatlanAnalogiaSzSzint = 3
  const hibajelentesSzavazatSzSzint = 5
  const analogiaKeresoSzSzint = 9

  const isUserAboveLevel = (level) => user.felhasznaloi_szint >= level;

  return {
    hibajelentesJog: isUserAboveLevel(hibajelentesSzSzint),
    posztLetrehozasJog: isUserAboveLevel(posztLetrehozasSzSzint),
    analogiaLetrehozasJog: isUserAboveLevel(analogiaLetrehozasSzSzint),
    analogiaErtekelesJog: isUserAboveLevel(analogiaErtekelesSzSzint),
    kommentelesJog: isUserAboveLevel(kommentelesSzSzint),
    hibajelentesSzavazatJog: isUserAboveLevel(hibajelentesSzavazatSzSzint),
    analogiaKeresoJog: isUserAboveLevel(analogiaKeresoSzSzint),
    besorolatlanAnalogiaJog: isUserAboveLevel(besorolatlanAnalogiaSzSzint),
    hibajelentesSzSzint,
    posztLetrehozasSzSzint,
    analogiaLetrehozasSzSzint,
    analogiaErtekelesSzSzint,
    kommentelesSzSzint,
    hibajelentesSzavazatSzSzint,
    analogiaKeresoSzSzint,
    besorolatlanAnalogiaSzSzint
  };
};

export const PermissionsProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const permissions = determinePermissions(user);

  return (
    <PermissionContext.Provider value={permissions}>
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermissions = () => useContext(PermissionContext);
