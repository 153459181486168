import React from 'react';
import { Table } from 'react-bootstrap';
import { useContext } from 'react';
import AuthContext from "../../userkezeles/AuthContext";
import { ActionButtonGroup } from './ActionButtonGroup';

function BesorolatlanAnalogiaList({ besorolatlanAnalogiak }) {
  const { user } = useContext(AuthContext);
  return (
    <>
      {besorolatlanAnalogiak && besorolatlanAnalogiak.length > 0 ? (
        <Table striped bordered hover className="mt-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Tartalom</th>
              <th>Feltöltő</th>
              <th>Abasztrakt analógia típus</th>
              {user.felhasznaloi_szint > 3 && (
              <th></th>
              )}
            </tr>
          </thead>
          <tbody>
            {besorolatlanAnalogiak.map((analogia, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{analogia.tartalom}</td>
                <td>@{analogia.feltolto}</td>
                <td>{analogia.aanalogia_tipus}</td>
                <td>
                  <ActionButtonGroup {...{analogia}}/>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p className="mt-4">Nincs megjeleníthető analógia javaslat.</p>
      )}
    </>
  );
}

export default BesorolatlanAnalogiaList;
