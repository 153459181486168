import React, { useContext, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthContext from "../../userkezeles/AuthContext";
import { AnalogiaBesorolas, KukaIcon } from '../../../utils/Ikonok';
import { useDelete } from "../../auth/apicalls";
import { showErrorAlert } from "../../alert/ErrorAlert";
import { useQueryClient } from "react-query";
import { usePermissions } from '../../userkezeles/PermissionContext';
import AnalogiaBesorolasModal from './AnalogiaBesorolasModal';

export const ActionButtonGroup = ({ analogia }) => {
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const { besorolatlanAnalogiaJog, analogiaLetrehozasJog } = usePermissions();
  console.log("...", analogiaLetrehozasJog);

  const [showEditModal, setShowEditModal] = useState(false);

  const { mutate: deleteAnalogiaBesorolas } = useDelete(`/api/besorolatlan_analogia/${analogia.id}/`, {
    onSuccess: () => {
      queryClient.invalidateQueries("/api/besorolatlan_analogia/");
    },
    onError: (error) => {
      showErrorAlert({
        title: "Nem sikerült törölni az analógia javaslatot!",
        text: error.response.data.user_error
      });
    },
  });

  const handleDelete = async () => {
    deleteAnalogiaBesorolas();
  };

  const handleEdit = () => {
    setShowEditModal(true);
  };

  return (
    <div style={{ display: 'flex', gap: '2px' }}>
      {analogiaLetrehozasJog && user.userid !== analogia.feltolto_id && (
        <button
          className="btn badge"
          onClick={handleEdit}
          style={{ width: '100%', whiteSpace: 'normal', display: 'inline-block' }}
        >
          <AnalogiaBesorolas />
        </button>
      )}
      {besorolatlanAnalogiaJog && user.userid === analogia.feltolto_id && (
        <button
          className="btn badge"
          onClick={handleDelete}
          style={{ width: '100%', whiteSpace: 'normal', display: 'inline-block' }}
        >
          <KukaIcon />
        </button>
      )}
      <AnalogiaBesorolasModal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        analogia={analogia}
      />
    </div>
  );
};
