import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./joinModal.css"; // Add custom CSS for transitions

const interestsList = [
    "Gyógyítás",
    "Önismeret",
    "Szimbólumok lefordítása",
    "Asztrológia és szimbólumok kapcsolata",
    "Tudatosság",
];

const JoinModal = ({ show, handleClose }) => {
    const [step, setStep] = useState(0);
    const [interests, setInterests] = useState({});
    const [level, setLevel] = useState("");
    const [isTransitioning, setIsTransitioning] = useState(false);

    const handleNext = () => {
        if (step < 2) {
            setIsTransitioning(true);
            setTimeout(() => {
                setStep(step + 1);
                setIsTransitioning(false);
            }, 500); // Transition duration
        }
    };

    const handlePrev = () => {
        if (step > 0) {
            setIsTransitioning(true);
            setTimeout(() => {
                setStep(step - 1);
                setIsTransitioning(false);
            }, 500); // Transition duration
        }
    };

    const handleCheckboxChange = (event) => {
        setInterests({
            ...interests,
            [event.target.name]: event.target.checked,
        });
    };

    const isStepOneValid = () => Object.values(interests).some((v) => v);

    const levels = [
        "Szkeptikus: Nem hiszek benne, de látni szeretném mi ez!",
        "Érdeklődő: Szeretnék többet tudni a szimbólumokról!",
        "Asztrológus, de szimbólumok világában kevésbé jártas!",
        "Szimbólum szakértő: Jártam asztrológiai szimbólum iskolába!",
    ];

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Csatlakozz hozzánk</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={`steps-container ${isTransitioning ? 'transitioning' : ''}`}>
                    {step === 0 && (
                        <div className="step active">
                            <h5>Mennyire ismered az asztrológiát?</h5>
                            <Form>
                                {levels.map((levelOption, index) => (
                                    <Form.Check
                                        key={index}
                                        type="checkbox"
                                        label={levelOption}
                                        checked={level === levelOption}
                                        onChange={() => setLevel(levelOption)}
                                    />
                                ))}
                            </Form>
                            <div className="button-container">
                                <div></div>
                                <NextButton onClick={handleNext} disabled={!level} />
                            </div>
                        </div>
                    )}
                    {step === 1 && (
                        <div className="step active">
                            <h5>Mi érdekel az asztrológiai szimbólumokkal kapcsolatban?</h5>
                            <Form>
                                {interestsList.map((interest, index) => (
                                    <Form.Check
                                        key={index}
                                        type="checkbox"
                                        name={`interest${index + 1}`}
                                        label={interest}
                                        checked={interests[`interest${index + 1}`] || false}
                                        onChange={handleCheckboxChange}
                                    />
                                ))}
                            </Form>
                            <div className="button-container">
                                <PrevButton onClick={handlePrev} />
                                <NextButton onClick={handleNext} disabled={!isStepOneValid()} />
                            </div>
                        </div>
                    )}
                    {step === 2 && (
                        <div className="step active">
                            <h5>Hozd létre fiókod</h5>
                            <div className="button-container">
                                <PrevButton onClick={handlePrev} />
                                <NextButton onClick={handleClose} />
                            </div>
                        </div>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

const NextButton = ({ onClick, disabled }) => (
    <Button onClick={onClick} disabled={disabled}>
        Tovább
    </Button>
);

const PrevButton = ({ onClick, disabled }) => (
    <Button onClick={onClick} disabled={disabled}>
        Vissza
    </Button>
);

export default JoinModal;
