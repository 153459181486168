import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useFetching, usePost } from "../auth/apicalls";
import { showErrorAlert, showSuccessAlert } from "../alert/ErrorAlert";
import { useQueryClient } from "react-query";

function CreateIranyzatModel({iranyzatData, showModal, setShowModal}) {
  const queryClient = useQueryClient();

  const [formData, setFormData] = useState({
    iranyzat_tipus: '',
    agazat: '',
    leiras: '',
    public_lathatosag: true
  });

  const { mutate: postIranyzatok } = usePost(`/api/iranyzat/`, {
    onSuccess: () => {
      queryClient.invalidateQueries('/api/iranyzat/');
      setShowModal(false);
      showSuccessAlert({title: "sikerült létrehozni"})
    },
    onError: (error) => {
      showErrorAlert({
        title: "Nem sikerült létrehozni az irányzatot!",
        text: error.response.data.user_error
      });
    },
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = () => {
    postIranyzatok(formData);
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
    <Modal.Header closeButton>
        <Modal.Title>Új Irányzat Létrehozása</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <Form>
        <Form.Group controlId="iranyzatTipus">
            <Form.Label>Irányzat Típus</Form.Label>
            <Form.Control as="select" name="iranyzat_tipus" value={formData.iranyzat_tipus} onChange={handleInputChange}>
            <option value="">Válassz típust</option>
            {iranyzatData.iranyzat_tipusok.map((tipus) => (
                <option key={tipus.id} value={tipus.id}>
                {tipus.nev}
                </option>
            ))}
            </Form.Control>
        </Form.Group>
        <Form.Group controlId="agazat">
            <Form.Label>Ágazat</Form.Label>
            <Form.Control
            type="text"
            name="agazat"
            value={formData.agazat}
            onChange={handleInputChange}
            />
        </Form.Group>
        <Form.Group controlId="leiras">
            <Form.Label>Leírás</Form.Label>
            <Form.Control
            as="textarea"
            rows={3}
            name="leiras"
            value={formData.leiras}
            onChange={handleInputChange}
            />
        </Form.Group>
        <Form.Group controlId="publicLathatosag">
            <Form.Check
            type="checkbox"
            label="Publikus láthatóság"
            name="public_lathatosag"
            checked={formData.public_lathatosag}
            onChange={handleInputChange}
            />
        </Form.Group>
        </Form>
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
        Mégse
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
        Mentés
        </Button>
    </Modal.Footer>
    </Modal>
  );
}

export default CreateIranyzatModel;
