import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useContext } from 'react';
import AuthContext from "../../userkezeles/AuthContext";
import { useFetching } from "../../auth/apicalls";
import { Loading } from "../../alert/Loading";
import { ErrorAlert } from "../../alert/ErrorAlert";
import CreateBesorolatlanAnalogia from './CreateBesorolatlanAnalogia';
import BesorolatlanAnalogiaList from './BerosorlatlanAnalogiaList';

function BesorolatlanAnalogiak() {
  const { user } = useContext(AuthContext);
  const { data: iranyzatData, isError, isLoading } = useFetching('/api/besorolatlan_analogia/');
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {isLoading && <Loading size={60} />}
      {isError && <ErrorAlert show={isError} text="" />}
      {iranyzatData && (
        <div className="mt-4">
          <h2 className="mt-3 text-center">Analógia javaslatok listája</h2>
          <div className="text-secondary text-center" style={{fontSize: "0.9rem"}}>
              Ezen a felületen lehetőségünk van olyan analógiákat javasolni feltöltésre, amelyek még nincsenek benne az analógia adatbázisban.
              <br/>A magasabb szintű felhasználók csemegézhetnek az ötletekből, mivel lenne érdemes bővíteni a meglévő analógiák tárházát.
              <br/>A feltöltött ötletekért +3 pont jár.
          </div>
          {user.felhasznaloi_szint > 3 && (
          <div className="d-flex justify-content-center mt-2" style={{ fontSize: "0.9rem" }}>
            <Button className="btn btn-success" onClick={() => setShowModal(true)}>
              Új analógia ötlet hozzáadása
            </Button>
          </div>
          )}

          <BesorolatlanAnalogiaList besorolatlanAnalogiak={iranyzatData} />

          <CreateBesorolatlanAnalogia {...{showModal, setShowModal }} />
        </div>
      )}
    </>
  );
}

export default BesorolatlanAnalogiak;
