import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetching } from "../auth/apicalls";
import { Loading } from "../alert/Loading";
import { ErrorAlert } from "../alert/ErrorAlert";

function IranyzatDetail() {
  const { id } = useParams(); // Get the iranyzat ID from the URL
  const { data: iranyzatData, isError, isLoading } = useFetching(`/api/iranyzat/${id}/`);

  return (
    <div className="iranyzat-detail mt-4">
      {isLoading && <Loading size={60} />}
      {isError && <ErrorAlert show={isError} text="Could not load iranyzat details" />}
      {iranyzatData && (
        <>
          <h2>{iranyzatData.iranyzat_tipus}</h2>
          <p><strong>Ágazat:</strong> {iranyzatData.agazat}</p>
          <p><strong>Leírás:</strong> {iranyzatData.leiras}</p>
          <p><strong>Láthatóság:</strong> {iranyzatData.public_lathatosag ? 'Publikus' : 'Privát'}</p>
        </>
      )}
    </div>
  );
}

export default IranyzatDetail;
